import React from 'react';
import PropTypes from 'prop-types';

import { graphql, Link } from 'gatsby';

import StaticPage from '../components/staticpage/StaticPage';
import SEO from '../components/SEO';

import '../components/categories/categories.css';

const CategoriesPage = ({ location, data }) => {
    const categories = data.categories.nodes;
    const pagepathname = location.pathname;

    return (
        <StaticPage title="Categories">
            <SEO
                title="Categories"
                pagepathname={pagepathname}
            />

            <ul className="categories">
                {categories.map(category => {
                    const { title, slug } = category;

                    return (
                        <li
                            key={`category-${slug.current}`}
                            className="categories__item"
                        >
                            <Link
                                to={`/categories/${slug.current}`}
                                className={`categories__link categories__link--${slug.current}`}
                            >
                                {title}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </StaticPage>
    );
};

export default CategoriesPage;

export const query = graphql`
    query CategoriesQuery {
        categories: allSanityCategory {
            nodes {
                slug {
                    current
                }
                title
            }
        }
    }
`;

CategoriesPage.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};
